import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "../app/shared/Spinner";
const User = lazy(() => import("./user/User"));
const Category = lazy(() => import("./category/Category"));
const Review = lazy(() => import("./review/Review"));
const Post = lazy(() => import("./post/Post"));
const Avatar = lazy(() => import("./avatar/Avatar"));
const Login = lazy(() => import("./user-pages/Login"));
const ChangePassword = lazy(() => import("./user-pages/ChangePassword"));
const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/admin/user" component={User} />
          <Route exact path="/admin/category" component={Category} />
          <Route exact path="/admin/review" component={Review} />
          <Route exact path="/admin/post" component={Post} />
          <Route exact path="/admin/avatar" component={Avatar} />
          <Route path="/admin/login" component={Login} />
          <Route path="/admin/change-password" component={ChangePassword} />
          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />
          <Redirect to="/admin/login" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
