export async function adminLogin(payload) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}auth/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  });
  return await response.json();
}
export async function adminChangePass(payload) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}auth/directResetPassword`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  });
  return await response.json();
}


export async function adminCheckAuth(payload) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}auth/authorized`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  });
  return await response.json();
}
